import pastaroller from "./pastaroller.png";
import calendar from "./calendar.png";
import stacking from "./stacking.png";
import aboutSquare from "./about_square.png";
import aboutRect from "../../static/me-small.png";

export const whatsNewSections = [
  {
    orientation: "left",
    title: "On the blog",
    text: `
Anything can be a wax carving tool if you put your mind to it!

Take a look in your kitchen to find these utensils I use for wax carving. 
 `,
    link: `/blog/post/unconventional-tools-kitchen`,
    linktext: `Read unconventional kitchen tools`,
    image: pastaroller,
    imagealt: `Close up of bench peg with a pasta roller machine on top`,
  },

  {
    orientation: "right",
    title: `Behind the scenes`,
    text: `
I guess now is the perfect time to plan out the entire year?

So many ideas for new things this year, and you know what? There’s plenty of time! 

I don’t have to do everything in January! Pfew!

New things are coming, soon-ish.

`,
    image: calendar,
    imagealt:
      "2025 wall calendar for 2025 with a few things marked already and a set of markers on top of the calendar",
  },

  {
    orientation: "left",
    title: `With the classes`,
    text: `
The start of the year is a great time to revisit the very first class I released: Stacking rings.

It’s a perfect introductory class to wax carving!

Learn all the basics of wax carving and carve a set of simple stacking rings! 
`,
    link: `class/stacking-rings`,
    linktext: `Show me the stacking ring class!`,
    image: stacking,
    imagealt:
      "Close up of forefinger and thumb holding a stack of 6 rings alernating ones from wax and silver",
  },
];

export const blurbsection = {
  text: `Wax carving is a fun jewellery making technique where you transform an unassuming piece of wax into a beautiful piece of jewellery! Learn to carve rings,  3-dimensional shapes, classic pieces, and bold statement jewellery. 

Anything is possible in wax!

Through video tutorials you learn to carve your own piece of jewellery ready to be cast in the metal of your choice. Want to know what you can make?`,
  linktext: `Yes! Show me the classes!`,
  link: `/classes`,
};

export const aboutsection = {
  title: `Hi I'm Sandy!`,
  text: `I’m the CEO (Chief EVERYTHING Officer) here at Wax Carvers.
  
I did my first jewellery making class in 2012 and haven’t looked back since. I fell in love with wax carving in 2018 and I’m excited to share that love with you!
`,
  linktext: `Want to know more?`,
  link: `/about`,
  squarephoto: aboutSquare,
  rectphoto: aboutRect,
};

export const blogsection = {
  text: `
Do you want to know what tools you need to get started with wax carving?

Do you want to know what wax color you should get?

Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?

All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving.
`,
  linktext: `Let's read that blog!`,
  link: `/blog`,
};
