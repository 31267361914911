import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { useStripeCheckout } from "../hooks/useStripeCheckout";
import { Link as AnchorLink } from "react-scroll";
import { Section } from "../components/Layout/Section";
import photoSmallMe from "../static/me-small.png";
import { PageSubTitle, PageTitle } from "../components/Common/PageTitle";
import {
  OnlineAndMaterialBuySection,
  OnlineOnlyBuySection,
} from "../components/BuySections";

const MATERIAL_BOX_PRODUCT_ID_TRAILBLAZER = "prod_OcN9WlWwV6wDfH";
const ONLINE_ONLY_PRODUCT_ID_TRAILBLAZER = "prod_OcN9kKk8Rx2dNs";
const MATERIAL_BOX_PRODUCT_ID_EXPLORER = "prod_OcN8OwYaFlkucd";
const ONLINE_ONLY_PRODUCT_ID_EXPLORER = "prod_OcN7LLXrDTNOhj";
const MATERIAL_BOX_PRODUCT_ID_BOTH = "prod_PDlQBltednp1yy";
const ONLINE_ONLY_PRODUCT_ID_BOTH = "prod_OqAfT27E9Lunlx";
const extraBodyArgs = {
  cancel_url: "https://www.waxcarvers.com/advent-calendar",
  success_url: "https://www.waxcarvers.com/advent-calendar",
};
const importAll = (r, cache) =>
  r.keys().forEach((key) => (cache[key] = r(key)));
const imageFiles = {};
importAll(
  require.context(`../content/advent-calendar/`, true, /\.(png|gif|jpg)$/),
  imageFiles,
);
const transformImageUri = (slug) => (uri) => {
  const imgPath = `./${slug}/${uri.substring(2)}`;
  const importedImg = imageFiles[imgPath];
  return importedImg ? importedImg.default : "";
};

const Spinny = ({ text = "Creating Order" }) => (
  <span className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-indigo-50 sm:w-auto">
    <svg
      className="animate-spin w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
        clipRule="evenodd"
      />
    </svg>
    &nbsp;
    {text}
  </span>
);

const Divider = ({ text, margins = {} }) => (
  <div
    className={`relative ${margins.b ? "mb-5" : ""} ${
      margins.t ? "mt-10" : ""
    }`}
  >
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300"></div>
    </div>
    <div className="relative flex justify-center">
      <span className="px-3 bg-white text-lg font-medium text-gray-900">
        {text}
      </span>
    </div>
  </div>
);

const ExplorerClasses = () => {
  const {
    loading: loadingBoxedExplorer,
    redirectToCheckout: redirectToBoxedExplorerCheckout,
  } = useStripeCheckout(
    {
      id: MATERIAL_BOX_PRODUCT_ID_EXPLORER,
    },
    {
      ...extraBodyArgs,
      withShipping: true,
    },
  );

  const {
    loading: loadingOnlineExplorer,
    redirectToCheckout: redirectToOnlineExplorerCheckout,
  } = useStripeCheckout(
    {
      id: ONLINE_ONLY_PRODUCT_ID_EXPLORER,
    },
    extraBodyArgs,
  );
  return (
    <ul
      role="list"
      className="border border-gray-500 rounded-md divide-y divide-gray-500"
    >
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="sm:ml-3 flex-shrink-0">
          {loadingOnlineExplorer ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToOnlineExplorerCheckout}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Explorer €150&nbsp;&nbsp;
              </button>
            </div>
          )}
        </div>

        <div className="flex-1 items-center mb-4">
          <p className="ml-12 flex-1">
            <b>Explorer classes.</b> You get access to all the Explorer classes
            and use your own wax and tools.
          </p>
        </div>
      </li>
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="flex-shrink-0">
          {loadingBoxedExplorer ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToBoxedExplorerCheckout}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Explorer+ €210 *
              </button>
            </div>
          )}
          <div className="flex">
            <small>(plus shipping, fees and duties)</small>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="ml-12">
            <b>Explorer classes + material box.</b> You get access to all the
            Explorer classes <b>AND</b> you receive a box with wax for the
            different projects. You need to use your own tools. &nbsp;
          </p>
        </div>
      </li>
    </ul>
  );
};

const CombinedClasses = () => {
  const {
    loading: loadingBothBoxed,
    redirectToCheckout: redirectToBoxedBothCheckout,
  } = useStripeCheckout(
    {
      id: MATERIAL_BOX_PRODUCT_ID_BOTH,
    },
    {
      ...extraBodyArgs,
      withShipping: true,
    },
  );

  const {
    loading: loadingOnlineBoth,
    redirectToCheckout: redirectToOnlineBothCheckout,
  } = useStripeCheckout(
    {
      id: ONLINE_ONLY_PRODUCT_ID_BOTH,
    },
    extraBodyArgs,
  );
  return (
    <ul
      role="list"
      className="border border-gray-500 rounded-md divide-y divide-gray-500 my-5"
    >
      <li className="py-3 px-2 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="sm:ml-3 flex-shrink-0">
          {loadingOnlineBoth ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToOnlineBothCheckout}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Trailblazer and Explorer €275
              </button>
            </div>
          )}
        </div>

        <div className="flex-1 items-center mb-4">
          <p className="ml-12 flex-1">
            <b> Trailblazer and Explorer classes:</b> You get access to all the
            Trailblazer and Explorer classes and use your own wax and tools.
          </p>
        </div>
      </li>
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="flex-shrink-0">
          {loadingBothBoxed ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToBoxedBothCheckout}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Trailblazer and Explorer + €385 *
              </button>
            </div>
          )}
          <div className="flex items-center justify-center">
            <small>(plus shipping, fees and duties)</small>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="ml-12">
            <b>Trailblazer and Explorer classes + material boxes</b>: You get
            access to all the Trailblazer and Explorer classes <b>AND</b> you
            receive a box with wax for ALL the different projects. You need to
            use your own tools. &nbsp;
          </p>
        </div>
      </li>
    </ul>
  );
};

const TrailblazerClasses = () => {
  const {
    loading: loadingBoxedTrailblazer,
    redirectToCheckout: redirectToBoxedTrailblazerCheckout,
  } = useStripeCheckout(
    {
      id: MATERIAL_BOX_PRODUCT_ID_TRAILBLAZER,
    },
    {
      ...extraBodyArgs,
      withShipping: true,
    },
  );

  const {
    loading: loadingOnlineTrailblazer,
    redirectToCheckout: redirectToOnlineTrailblazerCheckout,
  } = useStripeCheckout(
    {
      id: ONLINE_ONLY_PRODUCT_ID_TRAILBLAZER,
    },
    extraBodyArgs,
  );

  return (
    <ul
      role="list"
      className="border border-gray-500 rounded-md divide-y divide-gray-500 my-5"
    >
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col md:flex-row">
        <div className="flex-1 items-center mb-4">
          <p className="ml-2 flex-1">
            <b>Trailblazer classes.</b> You get access to all the Trailblazer
            classes and use your own wax and tools.
          </p>
        </div>
        <div className="ml-4 flex-shrink-0">
          {loadingOnlineTrailblazer ? (
            <Spinny nomt />
          ) : (
            <div className="flex flex-col">
              <button
                onClick={redirectToOnlineTrailblazerCheckout}
                className="inline-flex items-center justify-center px-7 py-3 border border-transparent
                 text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Trailblazer €150
              </button>
            </div>
          )}
        </div>
      </li>
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col md:flex-row">
        <div className="flex-1 flex items-center mb-4">
          <div className="flex flex-col">
            <p className="ml-2 flex-1">
              <b>Trailblazer classes + material box.</b> You get access to all
              the Trailblazer classes <b>AND</b> you receive a box with wax for
              the different projects. You need to use your own tools. &nbsp;
            </p>
          </div>
        </div>
        <div className="ml-4 flex flex-col items-center md:items-end">
          {loadingBoxedTrailblazer ? (
            <Spinny nomt />
          ) : (
            <div className="flex">
              <div>
                <button
                  onClick={redirectToBoxedTrailblazerCheckout}
                  className="flex-shrink-0 inline-flex items-center justify-center px-5 py-3 border border-transparent
                 text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                >
                  Trailblazer+ €200 *
                </button>
              </div>
            </div>
          )}
          <div className="flex">
            <small>(plus shipping, fees and duties)</small>
          </div>
        </div>
      </li>
    </ul>
  );
};

const PriceCard = () => {
  return (
    <div
      id="purchase"
      className="bg-blue-100 mt-2 relative max-w-screen-lg mx-auto py-8 px-4 sm:px-6 lg:px-8 rounded-md"
    >
      <div className="max-w-md mx-auto lg:max-w-3xl">
        <dd className="mt-8 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <ExplorerClasses />
          <TrailblazerClasses />
          <CombinedClasses />
        </dd>
      </div>
    </div>
  );
};

const CombinedPriceCard = () => {
  return (
    <div
      id="purchase"
      className="mt-16 relative max-w-screen-lg mx-auto py-8 px-4 sm:px-6 lg:px-8 rounded-md"
    >
      <div className="max-w-md mx-auto lg:max-w-3xl">
        <div className="text-center py-8">
          <h2 className="text-3xl tracking-tight text-blue-700 mx-auto">
            <div className="mb-6">I can’t choose, I want both!</div>
          </h2>
        </div>
        <dd className="mt-8 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <CombinedClasses />
        </dd>
      </div>
    </div>
  );
};

const AdventCalendar = ({ pageContext: { content } }) => {
  const bannerImg =
    "http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png";
  const attributes = {
    title: "Wax Carvers Advent Calendar",
    description:
      "Do you want a creative advent calendar? With the wax carvers advent calendar, you learn 12 wax carving techniques and 12 full makes, ready for casting.",
  };
  return (
    <Layout full>
      <Helmet>
        <title>{attributes.title} ¦ Wax Carvers</title>
        <meta name="description" content={attributes.description} />
        <meta
          property="og:title"
          content={`${attributes.title} | Wax Carvers`}
        />
        <meta property="og:image" content={bannerImg} />
        <meta
          property="og:url"
          content={`https://www.waxcarvers.com/advent-calendar`}
        />
        <meta property="og:description" content={attributes.description} />

        <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
        <meta itemProp="description" content={attributes.description} />

        <meta
          property="twitter:title"
          content={`${attributes.title} | Wax Carvers`}
        />
        <meta property="twitter:description" content={attributes.description} />
        <meta property="twitter:card" content={bannerImg} />
        <meta property="twitter:image:src" content={bannerImg} />
      </Helmet>
      <div className="bg-white">
        <div className="m-12">
          <PageTitle title={content.a.attributes.title} />
        </div>
        <main>
          <div className="hidden sm:max-w-3xl sm:mx-auto sm:p-6 sm:block lg:p-8">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src={transformImageUri("a")(content.a.attributes.image)}
                  alt="Advent Calendar overview"
                  className="h-full w-full object-cover"
                />

                <div className="absolute inset-0 mix-blend-multiply"></div>
              </div>
              <div className="relative px-4 py-64 sm:px-6 sm:p-24 lg:p-32 lg:px-8">
                <div className="py-16">
                  <div className="mt-16  mx-12">
                    <div className=" text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden mx-auto sm:p-6">
            <img
              src={transformImageUri("a")(content.a.attributes.image)}
              alt="Advent Calendar Overview"
              className="h-full w-full object-cover"
            />
          </div>
          <PageSubTitle title={content.a.attributes.subtitle} />

          <div className="bg-blue-600 md:mt-4">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              <p className="text-xl text-white tracking-wide whitespace-pre-wrap text-center mt-12">
                <ReactMarkdown
                  children={content.a && content.a.body ? content.a.body : ""}
                />
              </p>
              <div className="lg:flex-shrink-0 mx-12">
                <div className="rounded-md text-center">
                  <AnchorLink
                    to="purchase"
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <div
                      className="mt-16 mb-8 w-48 mx-auto text-base font-bold text-blue-700 hover:text-blue-800 sm:text-lg cursor-pointer
                  md:text-inline-flex items-center justify-center py-3 border border-transparent rounded-md bg-gray-50 hover:bg-blue-100"
                    >
                      Yes that's me!
                    </div>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>

          {["b", "c"].map((it, idx) => {
            return (
              <Section
                key={it}
                blue={it === "c" || it === "g"}
                item={{
                  orientation: idx % 2 === 0 ? "right" : "left",
                  title: content[it].attributes.title,
                  text: content[it].body,
                  image: transformImageUri(it)(content[it].attributes.image),
                  imageAlt:
                    content[it].attributes.imageAlt ??
                    content[it].attributes.title,
                }}
                transformImageUri={transformImageUri(it)}
                asMarkdown={true}
              />
            );
          })}

          <div className="bg-blue-600">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              <p className="text-xl text-white tracking-wide  text-center my-3">
                Don’t like surprises and want to know exactly what you’re
                getting? Click the link to see the full class overview.
              </p>

              <p className="text-xl text-white tracking-wide  text-center my-3">
                DO NOT CLICK THE LINK IF YOU WANT TO BE SURPRISED EVERY DAY!
              </p>

              <p className="text-xl text-white tracking-wide text-center my-3">
                Show me all the{" "}
                <a className="text-blue-300" href="/trailblazer-contents">
                  Trailblazer
                </a>{" "}
                classes and{" "}
                <a className="text-blue-300" href="/explorer-contents">
                  {" "}
                  Explorer
                </a>{" "}
                classes.
              </p>
            </div>
          </div>

          {["d", "e", "g"].map((it, idx) => {
            return (
              <Section
                key={it}
                blue={it === "c" || it === "g"}
                item={{
                  orientation: idx % 2 === 0 ? "right" : "left",
                  title: content[it].attributes.title,
                  text: content[it].body,
                  image: transformImageUri(it)(content[it].attributes.image),
                  imageAlt:
                    content[it].attributes.imageAlt ??
                    content[it].attributes.title,
                }}
                transformImageUri={transformImageUri(it)}
                asMarkdown={true}
              />
            );
          })}

          <Divider margins={{ t: true, b: true }} />
          <div className="text-center py-4">
            <h2 className="text-3xl tracking-tight text-blue-700 mx-auto">
              This is EXACTLY what I want! I love to get the
            </h2>
          </div>
          <PriceCard />
          <div className="text-gray-900 max-w-screen-lg text-md rounded-b-lg px-6 py-4 sm:px-10 sm:py-4 text-center mx-auto">
            <p className="mb-3">*Material boxes ship January 3rd 2025</p>
            <p>
              There's nothing wrong with your math! You get a €25 discount when
              you buy both the calendars.
            </p>
            <br />
            <p>
              If your country isn’t listed as a shipping destination, email
              me:&nbsp;
              <a href="mailto:info@waxcarvers.com">info@waxcarvers.com</a>. I
              have to add all the countries manually. You're the first in your
              country to buy the advent calendar!
              <br />
            </p>
          </div>
          <Divider margins={{ t: true, b: true }} />

          {["h", "j"].map((it) => (
            <div key={content[it].slug}>
              <div
                className={`${
                  it === "j" ? "bg-blue-100" : ""
                } relative overflow-hidden`}
              >
                <div className="relative pt-2 pb-12 lg:pb-20">
                  <div
                    className={`mx-auto max-w-screen-xl mt-5 px-4 sm:px-6 md:mt-8 lg:mt-10`}
                  >
                    <div>
                      {content[it].attributes.onlineLink && (
                        <OnlineOnlyBuySection />
                      )}
                      {content[it].attributes.materialLink && (
                        <OnlineAndMaterialBuySection />
                      )}
                      <div className="prose prose-sm sm:prose lg:prose-lg mx-auto mt-20">
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          transformImageUri={transformImageUri(it)}
                          allowDangerousHtml
                          children={content[it].body}
                          components={{
                            blockquote: ({ node, ...props }) => {
                              const materialLink = props.children.some(
                                (child) =>
                                  child.props?.children?.includes(
                                    "materialLink",
                                  ),
                              );
                              const onlineLink = props.children.some((child) =>
                                child.props?.children?.includes("onlineLink"),
                              );
                              if (materialLink)
                                return <OnlineAndMaterialBuySection />;
                              if (onlineLink) return <OnlineOnlyBuySection />;
                              return <em {...props} />;
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}

          <section
            className="relative bg-white mt-12"
            aria-labelledby="join-heading"
          >
            <div
              className="hidden absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:block"
              aria-hidden="true"
            ></div>
            <div className="max-w-7xl mx-auto  md:bg-transparent lg:px-8">
              <div className="lg:grid lg:grid-cols-12">
                <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                  <div
                    className="absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:hidden"
                    aria-hidden="true"
                  ></div>
                  <div className="mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                    <div className="aspect-w-10 aspect-h-8 sm:aspect-w-2 md:aspect-h-2 lg:aspect-w-1 lg:aspect-h-1">
                      <img
                        className="object-cover object-center md:rounded-3xl shadow-2xl"
                        src={photoSmallMe}
                        alt="Photo of me, Sandy."
                      />
                    </div>
                  </div>
                </div>

                <div className="relative bg-blue-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                  <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                    <h2 className="text-3xl text-white" id="join-heading">
                      Tutor
                    </h2>
                    <p className="text-lg text-white whitespace-pre-wrap">
                      {content && content.tutor ? content.tutor.body : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="bg-blue-100 lg:py-5 rounded mt-12">
              <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-center">
                <h2 className="text-2xl text-bold tracking-tight text-gray-700 text-center">
                  <span className="block">Always know what I'm up to!</span>
                </h2>
                <div className="lg:mt-0 lg:flex-shrink-0 mx-12 mt-8">
                  <div className="rounded-md text-center">
                    <a
                      href="https://newsletter.waxcarvers.com"
                      aria-label="Join newsletter"
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                    >
                      Get weekly emails!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default AdventCalendar;
